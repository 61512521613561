import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import BlogSingle from '../../components/BlogDetails'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const BlogDetails =() => {
    return(
        <Fragment>
           <Navbar Logo={Logo}/>
           <PageTitle pageTitle={'25 Best Hosting Compani All Over the World'} pagesub={'Blog'}/> 
            <BlogSingle/>
            <Newsletter/>
            <Footer ftLogo={Logo}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogDetails;






