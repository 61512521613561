import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import ShopSingle from '../../components/shopsingle'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const ShopSinglePage =() => {
    return(
        <Fragment>
           <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Shop Single'} pagesub={'Shop'}/> 
            <ShopSingle/>
            <Newsletter/>
            <Footer ftLogo={Logo} footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ShopSinglePage;

