import React from 'react';
import {Link} from 'react-router-dom'
import ins1 from '../../images/recent-posts/img-1.jpg'
import ins2 from '../../images/recent-posts/img-2.jpg'
import ins3 from '../../images/recent-posts/img-3.jpg'



import './style.css'

const BlogSidebar = (props) => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }

    return(
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="wpo-blog-sidebar">
                <div className="widget search-widget">
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        <li><Link to="/blog-details">Cloud Hosting <span>25</span></Link></li>
                        <li><Link to="/blog-details">Reseller Hosting <span>34</span></Link></li>
                        <li><Link to="/blog-details">Shared Hosting <span>50</span></Link></li>
                        <li><Link to="/blog-details">VPS Hosting<span>46</span></Link></li>
                        <li><Link to="/blog-details">Dedicated Hosting <span>15</span></Link></li>
                        <li><Link to="/blog-details">Domain Name <span>65</span></Link></li>
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Recent posts</h3>
                    <div className="posts">
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins1} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">6 Ideas for how you can avoid telling lie</Link></h4>
                                <span className="date">22 Sep 2021</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins2} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">The definitive list of digital products you can sell</Link></h4>
                                <span className="date">22 Sep 2021</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins3} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">The definitive list of digital products you can sell</Link></h4>
                                <span className="date">22 Sep 2021</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link to="/blog-details">Hosting</Link></li>
                        <li><Link to="/blog-details">Cloud Hosting</Link></li>
                        <li><Link to="/blog-details">Best Hosting</Link></li>
                        <li><Link to="/blog-details">VPS Hosting</Link></li>
                        <li><Link to="/blog-details">Wordpress</Link></li>
                        <li><Link to="/blog-details">200k</Link></li>
                        <li><Link to="/blog-details">Shared</Link></li>
                        <li><Link to="/blog-details">Domain</Link></li>
                        <li><Link to="/blog-details">Dadicated Hosting</Link></li>
                    </ul>
                </div>
            </div>
        </div>
     )
        
}

export default BlogSidebar;
