import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link}  from 'react-router-dom'
import tm1 from '../../images/team/img-1.jpg'
import tm2 from '../../images/team/img-2.jpg'
import tm3 from '../../images/team/img-3.jpg'
import './style.css'


class Team extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const team = [
            {
                tmImg: tm1,
                Title: 'Jenny Watmon',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm2,
                Title: 'Harry Abraham',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm3,
                Title: 'Ron Di-soza',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm2,
                Title: 'Benjir Walton',
                Sub: "SCG First Company",
            }
        ]
        return (
            <section className="team-section section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-lg-6 col-12">
                            <div className="section-title">
                                <h2>Our Creative Team</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="team-grids team-slider">
                                <Slider {...settings}>
                                    {team.map((team, tmm) => (
                                        <div className="grid" key={tmm}>
                                            <div className="img-holder">
                                                <img src={team.tmImg} alt="" />
                                            </div>
                                            <div className="details">
                                                <h3>{team.Title}</h3>
                                                <p>{team.Sub}</p>
                                                <ul>
                                                    <li><Link to="/about"><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="/about"><i className="ti-twitter-alt"></i></Link></li>
                                                    <li><Link to="/about"><i className="ti-pinterest"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;