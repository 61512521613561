import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const CtaSection = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-cta-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-cta-text">
                            <h2>Lets Get Started with Hastium Now</h2>
                            <div className="btns-wpo-cta">
                                <Link onClick={ClickHandler} to="/donate" className="theme-btn-s4">Creat Your Account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaSection;