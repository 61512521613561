import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import FaqSection from '../../components/FaqSection'
import GetinTouch from '../../components/GetinTouch'
import Testimonials from '../../components/Testimonials'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'


const FAQPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Frequently Asked Questions'} pagesub={'FAQ'}/> 
            <FaqSection/>
            <GetinTouch/>
            <Testimonials/>
            <Newsletter/>
            <Footer ftLogo={Logo} footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default FAQPage;
