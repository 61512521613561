import React from 'react'


const AllPlans = (props) => {

    const Plans = [
        {
          pIcon:'fi flaticon-ui',
          heading:"Highly secure network",
          pra:"Our theme architecture is designed for maximize security.",
        },
        {
          pIcon:'fi flaticon-support-1',
          heading:"Best Customer Support",
          pra:"We are providing the best customer support ever.",
        },
        {
          pIcon:'fi flaticon-first',
          heading:"Choose your data center",
          pra:"Our theme qualiy is outstarnding from all over the world.",
        },
        {
          pIcon:'fi flaticon-gear',
          heading:"Built for speed",
          pra:"Our theme architecture is designed for maximize security.",
        },
        {
          pIcon:'fi flaticon-hand',
          heading:"Staging areas",
          pra:"We are providing the best customer support ever.",
        },
        {
          pIcon:'fi flaticon-repair',
          heading:"Powerful administration",
          pra:"Our theme qualiy is outstarnding from all over the world.",
        },
    ]

    return(
        <div className="planing-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Included in all plans</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                     {Plans.map((plan, pln) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={pln}>
                            <div className="wpo-planing-single">
                                <div className="wpo-planing-icon">
                                    <i className={plan.pIcon}></i>
                                </div>
                                <div className="wpo-planing-text">
                                    <span>{plan.heading}</span>
                                    <p>{plan.pra}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AllPlans;