import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import SharedPage from '../SharedPage'
import CloudPage from '../CloudPage'
import WordpressPage from '../WordpressPage'
import VpsPage from '../VpsPage'
import DedicatedPage from '../DedicatedPage'
import EmailPage from '../EmailPage'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ServiceSinglePage from '../ServiceSinglePage'
import PricingPage from '../PricingPage'
import FAQPage from '../FAQPage'
import ShopPage from '../ShopPage'
import ShopSinglePage from '../ShopSinglePage'
import BlogPage from '../BlogPage' 
import BlogPageLeft from '../BlogPageLeft' 
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails' 
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide' 
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'  
import ContactPage from '../ContactPage' 
import LoginPage from '../LoginPage' 
import SignUpPage from '../SignUpPage' 
import ForgotPassword from '../ForgotPassword' 
      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/shared' component={SharedPage} />
            <Route path='/cloud' component={CloudPage} />
            <Route path='/wordpress' component={WordpressPage} />
            <Route path='/vps' component={VpsPage} />
            <Route path='/dedicated' component={DedicatedPage} />
            <Route path='/email' component={EmailPage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/service' component={ServicePage} />
            <Route path='/service-single' component={ServiceSinglePage}/>
            <Route path='/pricing' component={PricingPage}/>
            <Route path='/faq' component={FAQPage}/>
            <Route path='/shop' component={ShopPage} />
            <Route path='/shop-single' component={ShopSinglePage} />
            <Route path='/blog' component={BlogPage}/> 
            <Route path='/blog-left' component={BlogPageLeft}/>
            <Route path='/blog-fullwidth' component={BlogPageFullwidth}/> 
            <Route path='/blog-details' component={BlogDetails}/>
            <Route path='/blog-details-left' component={BlogDetailsLeftSiide}/> 
            <Route path='/blog-details-fullwidth' component={BlogDetailsFull}/>
            <Route path='/contact' component={ContactPage}/>
            <Route path='/404' component={ErrorPage}/>
            <Route path='/login' component={LoginPage}/>
            <Route path='/signup' component={SignUpPage}/>
            <Route path='/forgot-password' component={ForgotPassword}/> 
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
