import React from 'react'
import ab from '../../images/about.png'
import './style.css'

const About = (props) => {
    return(
        <section className="about-page-area section-padding">
        <div className="container">
            <div className="row">
                <div className="col col-md-8">
                    <div className="about-area">
                        <span>Who We Are</span>
                        <h2>We are providing the best domain & hosting service for last 12 years.</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. </p>
                        <ul>
                            <li>Various versions have evolved over the years.</li>
                            <li>Sometimes by accident, sometimes on purpose. </li>
                            <li>Injected humour and the like.</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-md-4">
                    <div className="about-img">
                        <img src={ab} alt=""/>
                    </div>
                </div>
            </div>
        </div> 
    </section>
    )
}

export default About;