import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tst1 from '../../images/testimonials/img-1.jpg'
import tst2 from '../../images/testimonials/img-2.jpg'
import tst3 from '../../images/testimonials/img-3.jpg'
import tst4 from '../../images/testimonials/img-4.jpg'
import tst5 from '../../images/testimonials/img-5.jpg'
import tst6 from '../../images/testimonials/img-6.jpg'


import './style.css'

class Testimonials extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade:true,
        };

        const testWrap = [
            {
               tImg:tst1,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Robert Frost",
               title:"- CEO of Brabantio",
            },
            {
               tImg:tst2,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Harry Abraham",
               title:"- CEO of First",
            },
            {
               tImg:tst3,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Benjir Walton",
               title:"- CEO of Brabantio",
            },
            {
               tImg:tst4,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Ron Di-soza",
               title:"- CEO of Brabantio",
            },
            {
               tImg:tst5,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Robert Frost",
               title:"- CEO of Brabantio",
            },
            {
               tImg:tst6,
               des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore tempor incididunt ut labore et dolore magna aliqua',
               name:"Robert Frost",
               title:"- CEO of Brabantio",
            },
        ]
        return (
            <div className="wpo-testimonial-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="wpo-testimonial-single-img">
                                <div className="wpo-testimonial-active">
                                    <Slider {...settings}>
                                        {testWrap.map((item, tst) => (
                                            <div className="item" key={tst}>
                                                <div className="wpo-testimonial-img">
                                                    <img src={item.tImg} alt="tesimonial"/>
                                                </div>
                                                <div className="wpo-testimonial-text">
                                                    <p>{item.des}</p>
                                                    <ul>
                                                        <li>{item.name}</li>
                                                        <li>{item.title}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Testimonials;