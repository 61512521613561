import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service/img-1.png'
import service2 from '../../images/service/img-2.png'
import service3 from '../../images/service/img-3.png'
import service4 from '../../images/service/img-4.png'
import service5 from '../../images/service/img-5.png'
import service6 from '../../images/service/img-6.png'
import './style.css'

const Service = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
      
    const serviceWrap = [
        {
           simg:service1,
           title:"Shared Hosting",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
        {
           simg:service2,
           title:"VPS Hosting",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
        {
           simg:service3,
           title:"Cloud Hosting",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
        {
           simg:service4,
           title:"Reseller Hosting",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
        {
           simg:service5,
           title:"Dedicated Hosting",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
        {
           simg:service6,
           title:"Domain Name",
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
           link:"/service-single"
        },
    ]

    return(
        <div className="wpo-service-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Our Services</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.map((item, srv) => (
                        <div className="col-lg-4 col-md-6 col-12 custom-grid" key={srv}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-icon">
                                    <img src={item.simg} alt="S-icon"/>
                                </div>
                                <div className="wpo-service-text">
                                    <h2>{item.title}</h2>
                                    <p>{item.des}</p>
                                    <Link onClick={ClickHandler} to={item.link}>See More...</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service;